import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useParams } from 'react-router-dom';
import { useMyBooking } from '../context/MyBooking';
import Map from '../components/Map'
import GoogleMapsLogo from '../images/googleMaps.png'


function Reservation() {
  const iframeRef = useRef(null);
  const { getBooking, booking, property } = useMyBooking();
  const { id } = useParams();
  const [printableReservationURL, setPrintableReservationURL] = useState('')
  

  useEffect(()=>{
    if(id !== ''){
        getBooking(id)
    }
  },[id])

  useEffect(()=>{
    if(booking && property) {
        console.log(booking);
        console.log(property); 
    }
  },[booking, property])

  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  function printReservation(){
    if(printableReservationURL =='/printable-reservation?id=' + id ){
      iframeRef.current.contentWindow.location.reload();
    } else {
      setPrintableReservationURL('/printable-reservation?id=' + id)
    }
}
  
  return (
    <>
      {booking  && property ? (
        <div className="w-[90%] max-w-[1300px] mx-auto py-[50px]">

          <div className="w-full h-[350px] mb-[40px]">
            <img
              className="w-full h-full object-cover rounded-2xl"
              src={property?.pictures?.[0]?.original || ''}
              alt={property?.title || 'Property Image'}
            />
            <div className="bg-black/10 backdrop-blur-[3px] -mt-[85px] rounded-b-2xl w-full mx-auto py-[20px] flex items-center justify-between">
              <h2 className="text-left ml-[30px] text-white text-[30px] font-bold [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)]">
                {property?.nickname || 'Property Title'}
              </h2>
              <Link to={`/property/${property?.nickname}`}>
                <i className="bi bi-box-arrow-up-right text-white text-[20px] mr-[20px]"></i>
              </Link>
            </div>
          </div>

          <div className="flex justify-between items-center h-[60px] mb-[30px] px-[10px]">
            <div className="flex flex-col items-center text-center h-full justify-center">
                <p className="text-left text-[20px] w-full"> Hello, {booking?.guest?.fullName || 'Guest'} 👋</p>
                <p className="text-left text-[18px] text-gray-500 w-full"> Below, you’ll find all the relevant details of your reservation.</p>
            </div>
            <div className="flex items-center h-full gap-[20px]">
                <button onClick={()=>printReservation()}> <i className="bi bi-printer text-[30px] text-gray-500 hover:text-blue"></i> </button>
                <button onClick={()=>printReservation()}> <i className="bi bi-envelope text-[30px] text-gray-500 hover:text-blue"></i> </button>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-[30px] mb-[50px]">
           <div>
                <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
                  <p> Full Name </p>
                  <p className="text-gray-400"> {booking.guest.fullName}</p>
                </div>
                <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
                  <p> Email </p>
                  <p className="text-gray-400"> {booking.guest.email}</p>
                </div>
                <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
                  <p> Booking ID </p>
                  <p className="text-gray-400">
                    {id === '' ? 'Loading ...' : id}
                  </p>
                </div>
                <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
                  <p> Property </p>
                  <p className="text-gray-400"> {property.nickname}</p>
                </div>
                <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
                  <p> Type </p>
                  <p className="text-gray-400"> {property.roomType}</p>
                </div>
                <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
                  <p> Address </p>
                  <p className="text-gray-400"> {property.address.street}, {property.address.city}, {property.address.state}</p>
                </div>
                <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
                  <p> Guests </p>
                  <p className="text-gray-400"> {booking.guestsCount} people </p>
                </div>
                <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
                  <p> Bedrooms </p>
                  <p className="text-gray-400"> {property.bedrooms} bedrooms </p>
                </div>
                <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
                  <p> Bathrooms </p>
                  <p className="text-gray-400"> {property.bathrooms} bathrooms </p>
                </div>

                <div className="flex flex-row justify-between mt-[20px]">
                 <div>
                   <h2 className="text-[20px] text-center my-[10px] text-blue font-medium">
                     {' '}
                     <i className="bi bi-box-arrow-in-left mr-[10px]"></i> Check-In{' '}
                   </h2>
                   <p className="text-center">{formatDate(booking.checkIn)} </p>
                   <p className="text-center">
                     {property.defaultCheckInTime} - {property.defaultCheckInEndTime}{' '}
                   </p>
                 </div>
                 <div>
                   <h2 className="text-[20px] text-center my-[10px] text-blue font-medium">
                     {' '}
                     <i className="bi bi-box-arrow-right mr-[10px]"></i> Check-Out{' '}
                   </h2>
                   <p className="text-center"> {formatDate(booking.checkOut)} </p>
                   <p className="text-center"> {property.defaultCheckOutTime} </p>
                 </div>
                </div>
           </div>
           <div>
                <Map
                  title={property.nickname}
                  lat={property.address.lat}
                  long={property.address.lng}
                  showLocation={true}
                  radius={15} 
                  height={400}
                  NotClickable={true}
                />
                <div
                    onClick={() => window.open(`https://google.com/maps/place/${property.address.lat},${property.address.lng}`, '_blank')}
                    className="flex items-center justify-center gap-[20px] border border-gray-200 mt-[40px] w-[300px] px-[20px] py-[10px] ml-auto rounded-lg hover:bg-blue hover:text-white"> 
                    <img
                        className="w-[30px]" 
                        src={GoogleMapsLogo} 
                    />
                    <p className="mt-[3px] "> Open in Google Maps </p>
                </div>
           </div>
          </div>
        
          <h2 className="text-[20px] text-left my-[10px] text-blue font-medium">
            <i className="bi bi-house-gear mr-[10px]"></i> Amenities
           </h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-[30px] w-[99%] ml-auto mr-0">
           {property.amenities.map((amenity, index) => (
             <div key={index} className="text-left flex gap-[20px] mb-[10px] items-center text-[18px] text-gray-500">
               <i className="bi bi-check-circle-fill text-blue"></i> {amenity}
             </div>
           ))}
          </div>
        
           <iframe src={printableReservationURL} ref={iframeRef} />
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
} 

export default Reservation;
