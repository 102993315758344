import { useContext } from 'react';
import { BookingContext } from "../context/Booking";
import { taxesTitle } from '../Utils/Helper';

const useTaxes = () => {
  const { booking, updateBalances } = useContext(BookingContext); // Access context
  const apiURL = process.env.REACT_APP_APIURL;

  const addTaxes = async () => {
    const taxes = [];
    try {
      await fetch(apiURL + '/api/getTaxes',{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          propertyID: booking.property._id,
        })
      })
      .then(response => response.json())
      .then(response => {       
        for(let i = 0; i < response.length; i ++){
          taxes.push({type: taxesTitle(response[i].type), porcentage: response[i].amount}) 
        }
        updateBalances({
          taxes: [...response.map((tax) => ({
            type: taxesTitle(tax.type),
            porcentage: tax.amount,
          }))],
        });
    })
    } catch (error){
      console.log(error);
    }

    return true
  };

  return { addTaxes };
};

export default useTaxes;
