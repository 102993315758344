import React, { useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';

const PaymentAnimation = ({ onStepChange }) => {
  const [step, setStep] = useState(0);

  const messages = ['Processing Payment ...', 'Sending Contract ...', 'Booking Completed !'];
  const components = [
    <div className="text-5xl text-blue-600 mb-4"><i className="text-gray-400 bi bi-credit-card-2-front"></i></div>, // Replace with your HTML for "Processing Payment"
    <div className="text-5xl text-blue-600 mb-4"><i className="text-gray-400 bi bi-envelope-at"></i></div>, // Replace with your HTML for "Sending Email"
    <div className="text-5xl text-blue-600 mb-4"><i className="text-green-500 bi bi-house-check"></i></div>, // Replace with your HTML for "Booking Complete"
  ];

  // Animation for the text and components
  const textAnimationProps = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(-20px)' },
    reset: false,
    config: { duration: 500 },
    key: step,
  });

  // Animation for the loading bar (progresses only once per step)
  const loadingBarAnimation = useSpring({
    from: { width: '0%' },
    to: { width: '100%' },
    config: { duration: 6000 },
    reset: false,
    key: step, // Triggers reset only when step changes
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setStep((prev) => (prev < messages.length - 1 ? prev + 1 : prev));
    }, 3000); // Change step every 3 seconds

    return () => clearInterval(interval);
  }, []);

  useEffect(()=>{
    onStepChange(step)
  },[step])

  return (
    <div className="flex flex-col items-center justify-center h-screen font-sans">
      <animated.div style={textAnimationProps} className="text-center">
        <div>{components[step]}</div>
        <div className="text-[20px] text-gray-500">{messages[step]}</div>
      </animated.div>
      <div className="mt-4 w-[300px] h-1.5 bg-gray-300 rounded overflow-hidden">
        <animated.div style={loadingBarAnimation} className="h-full bg-blue rounded" />
      </div>
    </div>
  );
};

export default PaymentAnimation;
