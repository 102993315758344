import React from 'react';
import { FaDoorOpen, FaToilet, FaUsers } from 'react-icons/fa';

function PropertyCard({ property, index }) {
  return (
    <div className="border rounded-lg shadow-lg p-4 space-y-4 w-full max-w-[400px] mx-auto">
      <div className="w-full h-52">
        <img
          src={property.pictures[0].original}
          alt={property.title}
          className="w-full h-full object-cover rounded-md"
        />
      </div>

      <p className="text-[22px] font-bold text-gray-800 text-left h-[60px]">{property.title}</p>

      <p className="text-gray-600 flex items-center">
        <i className="bi bi-geo-alt mr-2"></i>
        {property.address.city}, {property.address.state}
      </p>

      <div className="flex justify-around text-gray-700 gap-2 h-[50px]">
        <div className="flex items-center gap-[10px] border border-gray-200 px-2 py-1 rounded">
          <FaUsers className="text-blue text-[25px]" />
          <p className="text-sm">{property.accommodates} <br></br>people</p>
        </div>
        <div className="flex items-center gap-[10px] border border-gray-200 px-2 py-1 rounded">
          <FaDoorOpen className="text-blue text-[25px]" />
          <p className="text-sm">{property.bedrooms} <br></br> bedrooms</p>
        </div>
        <div className="flex items-center gap-[10px] border border-gray-200 px-2 py-1 rounded">
          <FaToilet className="text-blue text-[25px]" />
          <p className="text-sm">{property.bathrooms} <br></br> bathrooms</p>
        </div>
      </div>

      <a href={`/property/${property.nickname}`}>
        <button className="w-full bg-blue border border-blue text-white py-2 rounded-md hover:bg-white hover:text-blue transition mt-4">
          Read More
        </button>
      </a>
    </div>
  );
}

export default PropertyCard;
