import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { app } from '../components/Firebase';
import { getFirestore, addDoc, collection, doc, getDoc, onSnapshot, updateDoc, setDoc } from "firebase/firestore";
import { formatDateToText, formatCurrency } from '../Utils/Helper';

function Contract() {
    const db = getFirestore(app);
    const { id } = useParams();
    const [document, setDocument] = useState()

    useEffect(()=>{
        if(id){
            getContract(id)
        }
    },[id])


    const getContract = async(id) => {
        try {
            const docRef = doc(db, "Documents", id); // Reference to the document
            const docSnap = await getDoc(docRef); // Fetch the document
            if (docSnap.exists()) {
              setDocument(docSnap.data())
              console.log(docSnap.data());
              
            } else {
              alert("Docuement Not Found. Please contact support.");
            }
        } catch (error) {
          console.error("Error fetching landlord:", error);
          alert("An error occurred while fetching the landlord's information. Please try again.");
        }
    }




  return (
    <>
    {document ? (
        <div className='py-[50px] -mt-[10px] bg-white md:bg-[#f4f9ff]'>
            <div className='bg-white max-w-[1200px] w-[90%]  md:border border-gray-200 mx-auto p-[10px] md:p-[40px] lg:p-[80px] pt-[10px] md:pt-[50px] md:shadow-xl'>
                <div className='border-b border-gray-500 pb-[10px] mb-[50px] hidden md:block'>
                    <img  
                        className="w-[200px]" 
                        src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1682964660/Final_Logo_1_byrdtx.png" 
                    /> 
                </div>
                <h2 className='text-[30px] font-semibold mb-[40px]'> Rental Agreement </h2>
                <p className='text-left text-[15px] mb-[20px]'> 
                    <b>1. THE PARTIES: </b> This Short-Term Rental Agreement (“Agreement”) made on <span className='border-b border-black px-[10px] text-[14px]'>{formatDateToText(document.data.checkIn)}</span> betweeen the following: TENANT: <span className='border-b border-black px-[10px] text-[14px]'>{document.data.tenantFullName}</span> ("Tenant"), and LANDLORD: <span className='border-b border-black px-[10px] text-[14px]'>{document.data.landlordFullName}</span>, with a mailing address of <span className='border-b border-black px-[10px] text-[14px]'>{document.data.landlordAddress}</span> ("Landlord").
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b>2. THE PREMISES: </b> The Landlord agrees to lease the described property below to the Tenant, and the Tenant agrees to rent from the Landlord.
                    <br/>
                    <br/><b>a.) Mailing Address:</b> <span className='border-b border-black px-[10px] text-[14px]'>{document.data.landlordAddress}</span>.
                    <br/><b>b.) Residence Type:</b> <span className='border-b border-black px-[10px] text-[14px]'>  {document.data.residenceType} </span>.
                    <br/><b>c.) Bedroom(s): </b> <span className='border-b border-black px-[10px] text-[14px]'> {document.data.bedrooms} </span>.
                    <br/><b>d.) Bathroom(s): </b> <span className='border-b border-black px-[10px] text-[14px]'> {document.data.bathrooms} </span>.
                    <br/><b>e,) Other: </b><span className='border-b border-black px-[60px] text-[14px]'> </span>.
                    <br/>
                    <br/> Here in after known as the "Premises". 
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 3. LEASE TERM: </b> The Tenant shall have access to the Premises under the terms of this Agreement for the following time period: (check one)
                    <br/>
                    <br/> <i class={document.data.fixedTerm ? "bi bi-check-circle-fill" : "bi bi-circle"}></i> <b>- Fixed Term.</b> The Tenant shall be allowed to occupy the Premises starting <span className='border-b border-black px-[10px] text-[14px]'> {formatDateToText(document.data.checkIn)} </span> at <span className='border-b border-black px-[10px] text-[14px]'> {document.data.checkInTime} </span> and ending <span className='border-b border-black px-[10px] text-[14px]'> {formatDateToText(document.data.checkOut)} </span> at <span className='border-b border-black px-[10px] text-[14px]'> {document.data.checkOutTime} </span> ("Leasing Term").
                    <br/> <i class={!document.data.fixedTerm ? "bi bi-check-circle-fill" : "bi bi-circle"}></i> <b> - Month-to-Month Lease. </b> The Tenant shall be allowed to occupy the Premises on a month-to-month arrangement starting <span className='border-b border-black px-[60px] text-[14px]'> </span>, and ending upon notice of <span className='border-b border-black px-[30px] text-[14px]'> </span> days from either Party to the other Party ("Lease Term").
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 4. QUIET HOURS: </b> The Landlord requires: (check one)
                    <br/>
                    <br/> <i class={document.data.quietHours ? "bi bi-check-circle-fill" : "bi bi-circle"}></i> <b> - Quiet Hours:  </b> Quiet hours begin at  22 : 00 PM each night and continue until sunrise. Quiet hours consist of no music and keeping all audio at a minimum level out of respect for the surrounding residents.
                    <br/> <i class={!document.data.quietHours ? "bi bi-check-circle-fill" : "bi bi-circle"}></i> <b> - No Quiet Hours: </b> There are no quiet hours. However, the Tenant must reside on the Premises with respect to the quiet enjoyment of the surrounding residents.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 5. OCCUPANTS: </b> The total number of individuals staying on the Premises during the Lease Term shall be a total of <span className='border-b border-black px-[10px] text-[14px]'> {document.data.guests} </span>. guests. If more than the authorized number of guests listed above are found on the Premises, this Agreement will be subject to termination by the Landlord.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 6. RENT: </b> The Tenant shall pay the Landlord:
                    <br/>
                    <br/> <i class={document.data.fixedAmount ? "bi bi-check-circle-fill" : "bi bi-circle"}></i> <b> - Fixed Amount: </b>   The Tenant shall be required to pay the Landlord <span className='border-b border-black px-[10px] text-[14px]'> {formatCurrency(document.data.accomodationFare)} </span> for the Lease Term (“Rent”). The Rent is due at the execution of this Agreement.
                    <br/> <i class={!document.data.fixedAmount ? "bi bi-check-circle-fill" : "bi bi-circle"}></i> <b> - Monthly Amount: </b> The Tenant shall be required to pay the Landlord $<span className='border-b border-black px-[40px] text-[14px]'> </span>  in equal monthly installments for the Lease Term (“Rent”) and due on the of each month under the following instructions: First (1st) month’s rent is due at the execution of this Agreement.      
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                   <b> 7. UTILITIES:</b> The Landlord shall be responsible for all utilities and services to the Premises EXCEPT for the following: <span className='border-b border-black px-[100px] text-[14px]'> </span>
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 8. SECURIT DEPOSIT: </b>The Tenant shall be obligated to pay the following amounts upon the execution of this Agreement: (check one)
                    <br />
                    <br/> <i class={!document.data.securitDeposit ? "bi bi-check-circle-fill" : "bi bi-circle"}></i> <b> -  No Security Deposit: </b> There is no deposit required for the security of this Agreement (“Security Deposit”).
                    <br/> <i class={document.data.securitDeposit ? "bi bi-check-circle-fill" : "bi bi-circle"}></i> <b> -  Security Deposit: </b> $ <span className='border-b border-black px-[40px] text-[14px]'> </span>(“Security Deposit”). The Security Deposit is for the faithful performance of the Tenant under the terms and conditions of this Agreement. The Tenant must pay the Security Deposit at the execution of this Agreement. The Security Deposit shall be returned to the Tenant within the State's requirements after the end of the Lease Term less any itemized deductions. This Security Deposit shall not be credited towards any Rent unless the Landlord gives their written consent.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 9. PETS: </b> The Landlord: (check one)
                    <br />
                    <br/> <i class={!document.data.petsAllowed ? "bi bi-check-circle-fill" : "bi bi-circle"}></i> <b> Does Not Allow Pets: </b>  There are no pets allowed on the Premises. If the Tenant is found to have pets on the Premises, this Agreement and any Security Deposit shall be forfeited.
                    <br/> <i class={document.data.petsAllowed ? "bi bi-check-circle-fill" : "bi bi-circle"}></i>  <b> Allow Pets: </b>  The Tenant shall have the right to have <span className='border-b border-black px-[40px] text-[14px]'> </span> pet(s) on the Premises with a maximum limit of <span className='border-b border-black px-[40px] text-[14px]'> </span> pounds per pet. For the right to have pet(s) on the Premises, the Landlord shall charge a fee of $ <span className='border-b border-black px-[40px] text-[14px]'> </span>that is  non-refundable  refundable unless there are damages related to the pet. The Tenant is responsible for all damage that any pet causes, regardless of the ownership of said pet, and agrees to restore the Premises to its original condition at their expense.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 10. PARKING:  </b>The Landlord: (check one)
                    <br />
                    <br/> <i class={document.data.parkingProvided ? "bi bi-check-circle-fill" : "bi bi-circle"}></i> <b> Shall provide </b> <span className='border-b border-black px-[40px] text-[14px]'> </span> parking space(s) to the Tenant for a fee of $ <span className='border-b border-black px-[40px] text-[14px]'> </span> to be paid  at the execution of this Agreement  on a monthly basis in addition to the rent. The parking space(s) are described as: [DESCRIBE PARKING SPACES]   <span className='border-b border-black px-[60px] text-[14px]'> </span>.
                    <br/> <i class={!document.data.parkingProvided ? "bi bi-check-circle-fill" : "bi bi-circle"}></i> <b> Shall Not provide </b> provide parking.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 11. FEES:  </b> The Landlord requires the Tenant pays the following fees at the execution of this Agreement: (check all that apply)
                    <br />
                    <br/> <i class="bi bi-check-circle-fill"></i> <b> Cleaning Fee: </b> <span className='border-b border-black px-[10px] text-[14px]'> {formatCurrency(document.data.cleaningFee)} </span>
                    <br/> <i class="bi bi-check-circle-fill"></i> <b> Taxes: </b> <span className='border-b border-black px-[10px] text-[14px]'> {formatCurrency(document.data.taxesTotal)} </span>
                    <br/> <i class="bi bi-circle"></i> <b> Other: </b> <span className='border-b border-black px-[40px] text-[14px]'> </span> 
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 12. PARTY CLEANUP: </b> If the Premises qualifies for a “deep clean” due to the amount of “wear and tear” from a party or large gathering, a fee of $ <span className='border-b border-black px-[40px] text-[14px]'> </span>  (“Party Cleanup Fee”) shall be charged at the end of the Lease Term. The Party Cleanup Fee may be deducted from the Security Deposit.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 13. SMOKING POLICY: </b> Smoking on the Premises is: (check one)
                    <br />
                    <br/> <i class={!document.data.smokingAlloweed ? "bi bi-check-circle-fill" : "bi bi-circle"}></i><b> - Prohibited </b>
                    <br/> <i class={document.data.smokingAlloweed ? "bi bi-check-circle-fill" : "bi bi-circle"}></i><b> - Prohibited </b>ONLY in the following areas: <span className='border-b border-black px-[80px] text-[14px]'> </span> .
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 14. PERSON OF CONTACT: </b> The Landlord: (check one)
                    <br/>
                    <br/><i class={document.data.hasAgent ? "bi bi-check-circle-fill" : "bi bi-circle"}></i><b> -Does </b> have a manager on the Premises that can be contacted for any maintenance or repair at:
                    <br/><b>Agent/Manager’s Name</b> <span className='border-b border-black px-[70px] text-[14px]'></span>.
                    <br/><b>Telephone</b> <span className='border-b border-black px-[70px] text-[14px]'></span>.
                    <br/><b>E-mail</b> <span className='border-b border-black px-[70px] text-[14px]'></span>.
                    <br/>
                    <br/><i class={!document.data.hasAgent ? "bi bi-check-circle-fill" : "bi bi-circle"}></i><b> -Does not </b> have an agent/manager on the Premises, although the Landlord can be contacted for any emergency, maintenance, or repair at:
                    <br/><b>Landlord's Name:</b> <span className='border-b border-black px-[10px] text-[14px]'>{document.data.landlordFullName}</span>.
                    <br/><b>Landlord's Telephone:</b> <span className='border-b border-black px-[10px] text-[14px]'>{document.data.landlordPhone}</span>.
                    <br/><b>Landlord's E-mail:</b> <span className='border-b border-black px-[10px] text-[14px]'>{document.data.landlordEmail}</span>.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 15. SUBLETING: </b> The Tenant: (check one)
                    <br/>
                    <br/><i class={document.data.subletingallowed ? "bi bi-check-circle-fill" : "bi bi-circle"}></i><b> - Has </b> the right to sublet the Premises. Each subtenant is: (check one)
                    <br/><i class={document.data.subletingallowed ? "bi bi-check-circle-fill" : "bi bi-circle"}></i>  required to be approved by the Landlord prior to occupancy.
                    <br/><i class={document.data.subletingallowed ? "bi bi-check-circle-fill" : "bi bi-circle"}></i>  not required to be approved by the Landlord.
                    <br/><i class={!document.data.subletingallowed ? "bi bi-check-circle-fill" : "bi bi-circle"}></i><b> - Does not </b> have the right to sublet the Premises.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 16. MOVE-IN INSPECTION: </b> Before, at the time of the Tenant accepting possession, or shortly thereafter, the Landlord and Tenant shall: (check one)
                    <br/>
                    <br/><i class={document.data.shouldInspect ? "bi bi-check-circle-fill" : "bi bi-circle"}></i><b>  - Inspect  </b> he Premises and write any present damages or needed repairs on a move-in checklist.
                    <br/><i class={!document.data.shouldInspect ? "bi bi-check-circle-fill" : "bi bi-circle"}></i><b> - Shall not  </b> inspect the Premises or complete a move-in checklist.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 17. INSPECTION : </b> The Landlord has the right to inspect the Premises with prior notice as in accordance with State law. Should the Tenant violate any of the terms of this Agreement, the rental period shall be terminated immediately in accordance with State law. The Tenant waives all rights to process if they fail to vacate the premises upon termination of the rental period. The Tenant shall vacate the Premises at the expiration time and date of this agreement.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 18. MAINTENANCE AND REPAIRS : </b> The Tenant shall maintain the Premises in a good, clean, and ready-to-rent condition and use the Premises only in a careful and lawful manner. The Tenant shall leave the Premises in a ready to rent condition at the expiration of this Agreement, defined by the Landlord as being immediately habitable by the next tenant. The Tenant shall pay for maintenance and repairs should the Premises be left in a lesser condition. The Tenant agrees that the Landlord shall deduct costs of said services from any Security Deposit prior to a refund if Tenant causes damage to the Premises or its furnishings.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 19. TRASH : </b> The Tenants shall dispose of all waste material generated during the Lease Term under the strict instruction and direction of the Landlord.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 20. QUIET ENJOYMENT : </b> The Tenant, along with neighbors, shall enjoy each other’s company in a quiet and respectful manner to each other’s enjoyment. The Tenant is expected to behave in a civilized manner and shall be good neighbors with any residents of the immediate area. Creating a disturbance of the area by large gatherings or parties shall be grounds for immediate termination of this Agreement.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 21. LANDLORD’S LIABILITY : </b> The Tenant and any of their guests hereby indemnify and hold harmless the Landlord against any and all claims of personal injury or property damage or loss arising from the use of the Premises regardless of the nature of the accident, injury or loss. The Tenant expressly recognizes that any insurance for property damage or loss which the Landlord may maintain on the property does not cover the personal property of Tenant and that Tenant should purchase their own insurance for their guests if such coverage is desired.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 22. ATTORNEY’S FEES : </b> The Tenant agrees to pay all reasonable costs, attorney's fees, and expenses that shall be made or incurred by the Landlord enforcing this agreement.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 23. USE OF PREMISES : </b> The Tenant shall use the Premises for residential use only. The Tenant is not authorized to sell products or services on the Premises or conduct any commercial activity.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 24. ILLEGAL ACTIVITY : </b> The Tenant shall use the Premises for legal purposes only. Any other such use that includes but is not limited to illicit drug use, verbal or physical abuse of any person or illegal sexual behavior shall cause immediate termination of this Agreement with no refund of pre-paid Rent.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 25. POSSESSIONS : </b> Any personal items or possessions that are left on the Premises are not the responsibility of the Landlord. The Landlord shall make every reasonable effort to return the item to the Tenant. If claims are not made within the State’s required time period or two (2) weeks, whichever is shorter, the Landlord shall be able to keep such items to sell or for personal use.
                </p>

                <p className='text-left text-[15px] mb-[20px]'> 
                    <b> 26. GOVERNING LAW. : </b> This Agreement shall be governed and subject to the laws located in the jurisdiction of Premise’s location.
                </p>
                



              

            </div>
        </div>
    ) : (
        <p> Loading .... </p>
    )}
    </>
  )
}

export default Contract
