import React, { useContext } from 'react';
import { PropertiesContext } from "../context/Properties";
import PropertyCard from '../components/PropertyCard';

function Rentals() {
  const { properties } = useContext(PropertiesContext);

  return (
    <div className="p-4 max-w-[90%] mx-auto">
      {properties.length === 0 ? (
        <div className='h-[80vh] flex items-center justify-center'>
            <p className="text-center">Loading Properties ....</p>
        </div>
      ) : (
        <div className="grid grid-cols-[repeat(auto-fit,_minmax(400px,_1fr))] gap-6 mx-auto">
          {properties.map((property, index) => (
            <PropertyCard key={property.key} property={property} index={index} />
          ))}
        </div>
      )}
    </div>
  );
}

export default Rentals;
