import React, { createContext, useState, useContext, useEffect } from 'react';
import { PropertiesContext } from './Properties'; // Import the PropertiesContext


// Create the context
const MyBookingContext = createContext();

// API base URL
const apiURL = process.env.REACT_APP_APIURL;

// Provider component
export const MyBookingProvider = ({ children }) => {
  const { properties } = useContext(PropertiesContext);
  const [booking, setBooking] = useState(null);
  const [property, setProperty] = useState(null);

  // Fetch booking details
  async function getBooking(bookingId) { 
    console.log('Getting Booking ....');
    
    try {
      const response = await fetch(`${apiURL}/api/getReservation`, {
        method: 'POST', // Specify POST method
        headers: {
          'Content-Type': 'application/json', // Inform server of JSON body
        },
        body: JSON.stringify( {bookingId} ), // Send bookingID in the request body
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setBooking(data)
    } catch (error) {
      console.error('Error fetching booking:', error);
    } 
  }

  useEffect(()=>{
    if(properties.length > 0 && booking) {
        const property = properties.find((prop) => prop.nickname === booking.listing.nickname) || null;
        setProperty(property)
    }
  },[properties])



  return (
    <MyBookingContext.Provider value={{ booking, property, getBooking }}>
      {children}
    </MyBookingContext.Provider>
  );
};

// Hook to use the BookingsContext
export const useMyBooking = () => {
  return useContext(MyBookingContext);
};
