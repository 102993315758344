import React, { createContext, useEffect, useState } from "react";

// Create the context
const UserContext = createContext();

// Create a provider component
const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    name: '',
    lastName: '',
    email: '',
    phone: '',
    photoId: null, // To store the uploaded Photo ID
  });

  const updateUser = (updatedFields) => {
    setUser((prev) => ({ ...prev, ...updatedFields }));
  };

  return (
    <UserContext.Provider 
        value={{ 
            user,
            updateUser 
        }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
