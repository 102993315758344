import React from 'react'
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

function Map(props) {
  if(props.NotClickable == true) {
    return (
      <div className='h-[props.height ? `${props.height}px` : 300px]'>
      <MapContainer center={[props.lat, props.long]} zoom={15} scrollWheelZoom={true} style={{ height: props.height ? `${props.height}px` : '300px',  borderRadius: props.radius ? `${props.radius}px` : '5px', boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Circle center={[props.lat, props.long]} radius={50} />
      </MapContainer>
      </div>
    )
  } else if (props.showLocation == true){
    return (
      <div className='h-[props.height ? `${props.height}px` : 300px]' onClick={() => window.open(`https://google.com/maps/place/${props.lat},${props.long}`, '_blank')}>
        <MapContainer center={[props.lat, props.long]} zoom={15} scrollWheelZoom={true} style={{ height: props.height ? `${props.height}px` : '300px',  borderRadius: props.radius ? `${props.radius}px` : '5px', boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Circle center={[props.lat, props.long]} radius={50} />
        </MapContainer>
      </div>
    )
  } else {
  return (
      <div className='wrapper-map'>
        <MapContainer center={[props.lat, props.long]} zoom={16} scrollWheelZoom={false} style={{ height: '400px', borderRadius: '10px', boxShadow: ' rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'}}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Circle center={[props.lat, props.long]} radius={100} />
        </MapContainer>
      </div>
  )
  }
}

export default Map
