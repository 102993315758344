import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useParams } from 'react-router-dom';
import { useMyBooking } from '../context/MyBooking';
import { db } from "../components/Firebase";
import {collection, getDoc, addDoc, updateDoc, doc, deleteDoc, query, where, setDoc} from "firebase/firestore";
import { formatDateOnly } from "../Utils/Helper";


function PrintReservation() {
  const { getBooking, booking, property } = useMyBooking();
  const { id } = useParams();
  const [data, setData] = useState([])
  const [landlord, setLandlord] = useState([])
  
  useEffect(()=>{
    if(id !== ''){
        getBooking(id)
    }
  },[id])

  useEffect(()=>{
    if(booking && property && data) {
        console.log(booking);
        console.log(property); 
        getLandlord(property.nickname)
        getContract(id)
    }
  },[booking, property])

  async function getLandlord(propertyNickname){
      const docRef = doc(db, "Landlords", propertyNickname);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log(docSnap.data());
        setLandlord(docSnap.data())
      } else {
          alert("FIREBASE ERROR")
      }
  }

  async function getContract(id){
    const docRef = doc(db, "Documents", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log(docSnap.data());
      setData(docSnap.data())
    } else {
        alert("FIREBASE ERROR")
    }
}


    return (
        <>
      {booking  && property ? (
      <div className="-mt-[100px]">
        <div className="border-b border-gray-300 pb-[20px] mb-[10px]">
            <img 
                className="w-[200px]"
                src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1682964660/Final_Logo_1_byrdtx.png" 
            />
        </div>
        <div>
            <h2 className="text-[25px] font-semibold text-left mb-[20px]"> Confirmed: {booking.nightsCount} nights in {property.nickname}</h2>
            <div className="grid grid-cols-2 mb-[30px]">
                <p className="text-left"> Booked by : {booking.guest.fullName}</p>    
                <p className="text-right"> Accepted </p>
                <p className="text-left"> {formatDateOnly(booking.checkIn)} </p>
                <p className="text-right"> {id} </p>
            </div>
            <div className="grid grid-cols-[40%_60%] gap-20px">
                {/* LEFT */}
                <div className="flex flex-col gap-[10px]">
                    <div className="grid grid-cols-3 items-center border border-gray-300 p-[20px]">
                        <div>
                            <p className="font-semibold"> Check In</p>
                            <p> {formatDateOnly(booking.checkIn).split(',')[0]}</p>
                            <p> {formatDateOnly(booking.checkIn).split(',')[1]}</p>
                        </div>
                        <i class="bi bi-chevron-right"></i>
                        <div>
                            <p className="font-semibold"> Check Out</p>
                            <p> {formatDateOnly(booking.checkOut).split(',')[0]}</p>
                            <p> {formatDateOnly(booking.checkOut).split(',')[1]}</p>
                        </div>
             
                    </div>
                    <div className="border border-gray-300 p-[20px] flex flex-col gap-[20px]">
                        <p className="text-left font-semibold"> {property.roomType} </p>
                        <p className="text-left text-[10px]"> {property.publicDescription.summary} </p>
                        <p className="text-left text-[10px]"> <b>Hosted By : </b> {data.fullName} </p>
                        <p className="text-left text-[10px]"> <b>Phone : </b> {data.phone} </p>
                        <p className="text-left text-[10px]"> <i class="bi bi-people-fill"></i> {booking.guestsCount} {booking.guestsCount > 1 ? 'Guests':'Guest'}  </p>
                        <p className="text-left text-[10px]"> {booking.guest.fullName} </p>
                    </div>
                </div>
                {/* RIGHT */}
                <div>   
                    <div className="border border-gray-300 p-[20]">
                        <p className="font-semmibold"> Charges </p>
                        <p><b>Nights: </b> {parseFloat(data.input[26]).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, useGrouping: true,})} USD</p>
                        <p><b>Cleaning Fee: </b> {parseFloat(data.input[38]).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, useGrouping: true,})} USD</p>
                        <p><b>Taxes: </b> {parseFloat(data.input[39]).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, useGrouping: true,})} USD</p>
                        <hr></hr>
                        <p id="total"><b>Total: </b> {(parseFloat(data.input[26]) + parseFloat(data.input[38]) + parseFloat(data.input[39])).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, useGrouping: true,})} USD</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    ): (
        <p>Loading...</p>
      )}
    </>
  );
}
      

export default PrintReservation
