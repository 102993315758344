import React, { useContext, useState, useEffect } from "react";
import { PropertiesContext } from "../context/Properties";

function Hero() {
  const { properties } = useContext(PropertiesContext);
  const [currentPropertyIndex, setCurrentPropertyIndex] = useState(0);
  const [selectedPictureIndex, setSelectedPictureIndex] = useState(0);
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    if (properties.length > 0 && !loading) {
      const intervalId = setInterval(() => {
        handleNext();
      }, 4000);
      return () => clearInterval(intervalId);
    }
  });

  useEffect(()=>{
    if(properties.length > 0) {
      setLoading(false)
    }
  },[properties])

  if (loading) {
    return (
      <div className="cover-loader">
        <img src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1683933526/Final_Logo_1_byrdtx_m9colt.png" />
        <div className="loader"></div>
      </div>
    )
  }

  const currentProperty = properties[currentPropertyIndex];

  const handleNext = () => {
    setCurrentPropertyIndex((prevIndex) =>
      prevIndex === properties.length - 1 ? 0 : prevIndex + 1
    );
    setSelectedPictureIndex(0); // Reset to the first picture when navigating to another property
  };

  const handleThumbnailClick = (index) => {
    setSelectedPictureIndex(index);
  };

  const slideStyle = {
    backgroundImage: `url(${currentProperty.pictures[selectedPictureIndex].original})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "70vh",
    borderRadius: "30px",
    transition: "background-image 1s ease-in-out",
  };

  return (
    <div className="wrapper-hero">
      <div className="slide" style={slideStyle}>
        <div className="hero-content">
          <div>
            <div className="hero-text">
              <h2 className="font-semibold">{currentProperty.title} </h2>
              <p id="location">
                {" "}
                <i className="bi bi-geo-alt"></i> {currentProperty.address.city}
                , {currentProperty.address.state}{" "}
              </p>
            </div>
          </div>
          <div className="galery">
            <div className="thumbnails">
              {currentProperty.pictures.slice(0, 3).map((picture, index) => (
                <img
                  className="thumbnail mb-[10px]"
                  key={index}
                  src={picture.original}
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => handleThumbnailClick(index)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
