import { useContext } from 'react';
import { BookingContext } from "../context/Booking";

const useTotal = () => {
  const { booking } = useContext(BookingContext);

  const getTotal = () => {
    if (!booking || !booking.balances) {
      console.warn("Booking or balances not initialized.");
      return 0; // Return 0 as a fallback
    }

    let total = 0;

    const { accommodationFare = 0, serviceFee = 0, extraGuestFee = 0, cleaningFee = 0, taxes = [] } = booking.balances;

    // Start with the base amounts
    total += accommodationFare;
    total += serviceFee;
    total += extraGuestFee;
    total += cleaningFee;

    // Add taxes (each tax is calculated as a percentage of accommodationFare)
    total += taxes.reduce(
      (sum, tax) => sum + (accommodationFare + serviceFee + cleaningFee) * (tax.porcentage / 100),
      0
    );

    console.log('TOTAL:' + parseFloat(total.toFixed(2)));
    

    return parseFloat(total.toFixed(2));
  };

  return getTotal; // Return the function, not the result
};

export default useTotal;
