import React, { useState, useContext } from 'react';
import { UserContext } from "../context/User";


const FileUpload = () => {
  const { user, updateUser } = useContext(UserContext); // Access context
  const [imageUpload, setImageUpload] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      updateUser({ photoId: file });
    }
  };

  return (
    <div className="flex flex-col items-center gap-4 mt-[30px] border border-gray-300 p-[20px] rounded"  htmlFor="file-upload">
      {user.photoId && (
        <div className="mt-4">
          <img
            src={URL.createObjectURL(user.photoId)}
            alt="Uploaded Preview"
            className=" h-[150px] object-cover rounded-lg drop-shadow-md border border-gray-300"
          />
        </div>
      )}
      <label
        htmlFor="file-upload"
        className="cursor-pointer bg-blue text-white py-[5px] px-[20px] rounded-full hover:bg-blue transition duration-300"
      >
        <i class="bi bi-person-vcard mr-[10px] text-[20px]"></i>  {!user.photoId ? 'Submit Photo ID' : 'Change Photo ID'}
      </label>
      <input
        id="file-upload"
        type="file"
        className="hidden"
        onChange={handleFileChange}
      />
       {!user.photoId && (
        <p className="text-sm text-gray-500 text-center">
          Choose a file to upload. <br />
          Supported formats: JPG, PNG, PDF.
        </p> 
       )}
    </div>
  );
};

export default FileUpload;
