import { useContext } from 'react';
import { BookingContext } from "../context/Booking";

const useCleaningFee = () => {
  const { booking, updateBalances } = useContext(BookingContext); // Access context
  
  const addCleaningFee = () => {
    updateBalances({
        cleaningFee: booking.property.prices.cleaningFee
    });

    return true
  };

  return { addCleaningFee };
};

export default useCleaningFee;
