import React, { useState, useContext, useEffect } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import securePayment from '../images/securePayment.png'
import { formatDate, formatDateToText, toUppercase } from '../Utils/Helper';
import { useNavigate } from 'react-router-dom';
import { BookingContext } from '../context/Booking';
import { app } from './Firebase';
import { getFirestore, addDoc, collection, doc, getDoc, onSnapshot, updateDoc, setDoc } from "firebase/firestore";
import useTotal from '../hooks/useTotal';
import useLastAvailability from '../hooks/useLastAvailability';


const StripePaymentForm = ({ nextStep, sendReservationId }) => {
  const { isStillAvailable } = useLastAvailability();
  const { booking } = useContext(BookingContext); // Access context
  const navigate = useNavigate(); // Initialize navigate for redirection
  const stripe = useStripe();
  const elements = useElements();
  const [paymentStatus, setPaymentStatus] = useState('');
  const [error, setError] = useState(null);
  const [landlord, setLandlord] = useState()
  const db = getFirestore(app);

  const getTotal = useTotal();

  const apiURL = process.env.REACT_APP_APIURL
  const stripeURL = process.env.REACT_APP_STRIPEURL
  const mailerURL = process.env.REACT_APP_MAILERURL;

  const cardStyle = {
    style: {
      base: {
        fontSize: '16px',
        color: '#32325d',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
      },
    },
  };

  useEffect(()=>{
    if(booking){
      getLandlord(booking.property.nickname)
    }
  },[booking])
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentStatus('Processing payment...');

    if (!stripe || !elements) {
      return; // Stripe.js has not loaded yet
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    if(await isStillAvailable() == true){
      try {
        // Call your backend to create a PaymentIntent
        const { data: clientSecret } = await axios.post(
          `${stripeURL}/api/processPayment`,
          { 
            amount: parseFloat((getTotal()*100).toFixed(2)), 
            description: `BETTER STAYS: ${booking.property.nickname}`, 
          }
        );
  
        // Confirm the card payment using the clientSecret
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardNumberElement,
            billing_details: {
              name: booking.user.name + ' ' + booking.user.lastName,
            },
          },
        });
  
        if (error) {
          setPaymentStatus(`Payment failed: ${error.message}`);
        } else if (paymentIntent.status === 'succeeded') {
          nextStep()
          setPaymentStatus('Payment successful!');
          console.log('PAYMENT COMPLETED !'); 
          await guestyReservation()
        }
      } catch (err) {
        console.error('Error during payment:', err);
        setPaymentStatus('Payment failed: An unexpected error occurred.');
      }
    } else {
      const userResponse = window.confirm('Some Dates are No Longer Available...');
      if (userResponse || userResponse === false) {
        window.location.reload();
      }
    }
  };

  const guestyReservation = async () => {
    try {
      const response = await fetch(apiURL + '/api/newReservationWGuest', {
          method: 'POST',
          body: JSON.stringify({ 
              name: booking.user.name,  
              lastName: booking.user.lastName,
              phone: booking.user.phone, 
              email: booking.user.email, 
              checkIn: booking.selectedDates[0],
              checkOut: booking.selectedDates[booking.selectedDates.length - 1],
              propertyID: booking.property._id,
              paid: getTotal(), 
              guests: booking.guests, 
          }),
          headers: {'Content-Type': 'application/json'}
      });

      if (!response.ok) {
          throw new Error('Failed to create reservation');
      } else {
        const reservationId = await response.json();
        sendReservationId(reservationId)
        await createContract(reservationId);
      }
    } catch (error) {
        console.error('Error creating reservation:', error);
    }
  }

  async function getLandlord(property) {
    try {
      const docRef = doc(db, "Landlords", property); // Reference to the document
      const docSnap = await getDoc(docRef); // Fetch the document
  
      if (docSnap.exists()) {
        const landlordData = docSnap.data(); // Extract data once
  
        // Update the state with the landlord's data
        setLandlord({
          fullName: landlordData.fullName,
          phone: landlordData.phone,
          email: landlordData.email,
          address: landlordData.address,
          mailingAddress: landlordData.mailingAddress,
        });
      } else {
        alert("Landlord not found. Please contact support.");
      }
    } catch (error) {
      console.error("Error fetching landlord:", error);
      alert("An error occurred while fetching the landlord's information. Please try again.");
    }
  }
  

  async function createContract(id){
    console.log('CREATING CONTRACT .....');
    
    function calculateTotalTaxes() {
      const taxes = booking.balances.taxes;
    
      if (taxes && typeof taxes === 'object') {
        const totTaxes = Object.values(taxes).reduce(
          (sum, tax) => sum + (booking.balances.accommodationFare + booking.balances.serviceFee + booking.balances.cleaningFee) * (tax.porcentage / 100),
          0
        );
    
        return totTaxes; // Return the calculated total taxes
      } else {
        console.error('Taxes is not a valid object.');
        return 0; // Return 0 if taxes is invalid
      }
    }
    

    let data = {
      checkIn:booking.selectedDates[0], 
      tenantFullName:`${booking.user.name} ${booking.user.lastName}`,
      landlordFullName:landlord.fullName, 
      landlordAddress:landlord.mailingAddress, 
      bedrooms:booking.property.bedrooms, 
      bathrooms:booking.property.bathrooms,
      checkInTime:booking.property.defaultCheckInTime, 
      checkOut:booking.selectedDates[booking.selectedDates.length -1], 
      checkOutTime:booking.property.defaultCheckOutTime, 
      guests:booking.guests, 
      accomodationFare:booking.balances.accommodationFare, 
      cleaningFee:booking.balances.cleaningFee, 
      taxesTotal:calculateTotalTaxes(),
      landlordPhone: landlord.phone, 
      landlordEmail: landlord.email, 
      residenceType : 'House', 
      fixedTerm: true, 
      quietHours: true, 
      fixedAmount: true, 
      securitDeposit: false, 
      petsAllowed: false, 
      parkingProvided: false, 
      smokingAllowed: false, 
      hasAgent:false, 
      subletingallowed: false, 
      shouldInspect: true, 
    }
    
    await setDoc(doc(db, "Documents",`ATTEMPT-${id}`), {
      date: formatDate(new Date()),
      data : data, 
      nameLandlord: landlord.fullName, 
      nameTenant1: `${booking.user.name} ${booking.user.lastName}`, 
      nameTenant2: "",
      signLandlord: "", 
      signTenant1: "",
      signTenant2: "",
      title: "Contract", 
      status: "available",
      propertyName: booking.property.nickname, 
      customerEmail: booking.user.email,
      customerName: booking.user.name, 
      customerLastName: booking.user.lastName,
      priceBreakDown: booking.balances
    });

    await sendContract(id)
  }

  async function sendContract(contractID){
      //sending Email  
      console.log("Sending Contract ...");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      
      var urlencoded = new URLSearchParams();
      urlencoded.append("name", booking.user.name);
      urlencoded.append("lastName", booking.user.lastName);
      urlencoded.append("email", booking.user.email);
      urlencoded.append("contractID", contractID);

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };
      
      await fetch(mailerURL + "/api/sendContract", requestOptions)
        .then(response => response.text())
        .then(result => console.log("Email Sent: " + result))
        .catch(error => console.log('== ERROR === ', error));
  }

  return (
    <div className="py-[50px] px-[20px]">
      <h2 className='text-[20px] text-left mb-[10px] -mt-[15px] text-blue font-medium'> <i class="bi bi-calendar2-week mr-[10px]"></i> Payment Details </h2>
      <p className='text-gray-500 text-left text-[15px] mb-[30px]'> Enter your payment information below to complete your booking. Simply fill in your card details, and let us handle the rest for a smooth and hassle-free checkout experience.  </p>
      {/* Payment Form */}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <div className="flex items-center">
            <i className="bi bi-person -mr-[24px] ml-[10px]"></i>
            <p className="text-gray-400 border border-gray-300 p-[5px] rounded-md text-left pl-[40px] w-[350px] w-full">
              {toUppercase(booking.user.name + ' ' + booking.user.lastName)}
            </p>
          </div>
        </div>

        <div className="mb-4">
          <div className="flex items-center">
            <i className="bi bi-credit-card-2-front -mr-[24px] ml-[10px]"></i>
            <CardNumberElement
              id="card-number"
              options={cardStyle}
              onChange={(e) => setError(e.error?.message || null)}
              className="p-2 border border-gray-300 rounded-md w-full pl-[40px]"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="mb-4">
            <div className="flex items-center">
              <i className="bi bi-calendar-week -mr-[24px] ml-[10px]"></i>
              <CardExpiryElement
                id="card-expiry"
                options={cardStyle}
                onChange={(e) => setError(e.error?.message || null)}
                className="p-2 border border-gray-300 rounded-md w-full pl-[40px]"
              />
            </div>
          </div>

          <div className="mb-4">
            <div className="flex items-center">
              <i className="bi bi-credit-card -mr-[24px] ml-[10px]"></i>
              <CardCvcElement
                id="card-cvc"
                options={cardStyle}
                onChange={(e) => setError(e.error?.message || null)}
                className="p-2 border border-gray-300 rounded-md w-full pl-[40px]"
              />
            </div>
          </div>
        </div>

        {error && <div className="text-red-500 mb-4">{error}</div>}
        
        <img src={securePayment} className='w-full mb-[30px]'/>

        <p className="my-4">{paymentStatus}</p>
        
        <button
          type="submit"
          disabled={!stripe}
          className={`bg-blue hover:bg-white hover:text-blue border border-blue text-white py-2 px-4 rounded-lg transition-all w-[80%] mx-auto block mt-4`}
        >
          Pay Now
        </button>
      </form>
      
    </div>
  );
};

export default StripePaymentForm;
