import { useContext } from 'react';
import { BookingContext } from "../context/Booking";

const useExtraGuestFee = () => {
  const { booking, updateBalances } = useContext(BookingContext); // Access context
  
  const addExtraGuestFee = () => {
    const guestIncluded = booking.property.prices.guestsIncludedInRegularFee;
    if(booking.guests > guestIncluded){
      const extraGuests = booking.guests - guestIncluded;
      const extraGuestFee = extraGuests * booking.property.prices.extraPersonFee;
      updateBalances({
        extraGuestFee: extraGuestFee
      });
    } else {
      updateBalances({
        extraGuestFee: 0
      });
    }

    return true
  };

  return { addExtraGuestFee };
};

export default useExtraGuestFee;
