const apiURL = process.env.REACT_APP_APIURL;

// CALLING THE API TO FETCH THE DATES 
export async function fetchCalendar(id) {
  try {
    const response = await fetch(apiURL + '/api/getCalendar', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ propertyID: id }),
    });

    const data = await response.json();
    return {
      disabledDates: data.data.days
        .filter((day) => day.status === 'unavailable' || day.status === 'booked')
        .map((day) => new Date(day.date)),
      availableDates: data.data.days
        .filter((day) => day.status === 'available')
        .map((day) => ({ date: new Date(day.date), price: day.price })),
    };
  } catch (error) {
    console.error('Error fetching calendar:', error);
    return { disabledDates: [], availableDates: [] }; // Return empty arrays on error
  }
}

  // FORMAT DATE TEXT TO dd/mm/yyyy
  export const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // RETURNS DATES IN BETWEEN FROM A RANGE
  export const getDatesInRange = (start, end) => {
    const dateArray = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
      dateArray.push(new Date(currentDate)); // Add the current date to the array
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    return dateArray.map(formatDate); // Format all dates as dd/mm/yyyy
  };


  // FORMAT DATE FROM DD/MM/YYYY TO TEXT
  export function formatDateToText(dateString) {
    const [day, month, year] = dateString.split("/").map(Number);
    
    // Array of month names
    const monthNames = [
      "January", "February", "March", "April", "May",
      "June", "July", "August", "September", "October",
      "November", "December"
    ];
    
    // Convert to text format
    const monthName = monthNames[month - 1]; // Month is 1-based
    return `${monthName} ${day}, ${year}`;
  }

  // RETURNS THE MONTH, DAY, AND YEAR IN A ARRAY
  export function dateElements(dateString) {
    const [day, month, year] = dateString.split("/").map(Number);
  
    // Array of month names
    const monthNames = [
      "January", "February", "March", "April", "May",
      "June", "July", "August", "September", "October",
      "November", "December"
    ];
  
    // Return array with month, day, and year
    return [monthNames[month - 1], day, year];
  }

  // FORMAT TAXES TITLE
  export function taxesTitle(str) {
    let words = str.toLowerCase().split('_');
    let titleCaseStr = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    return titleCaseStr;
  }

  // FORMAT CURRENCY
  export function formatCurrency(value, includeCurrency = true) {
    if (typeof value !== "number") {
      throw new Error("Value must be a number.");
    }
  
    return new Intl.NumberFormat("en-US", {
      style: includeCurrency ? "currency" : "decimal",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

  }

  export const toUppercase = (str) => {
    if (typeof str !== 'string') {
      throw new Error('Input must be a string');
    }
    return str.toUpperCase();
  };
  

  export const getFieldLabel = (field) => {
    const fieldLabels = {
      property: "Property is not selected",
      selectedDates: "Please select dates",
      guests: "Guests must be greater than 0",
      "user.name": "First name is missing",
      "user.lastName": "Last name is missing",
      "user.email": "Email is missing",
      "user.phone": "Phone number must be at least 10 digits",
      "user.photoId": "Photo ID is missing",
    };
    return fieldLabels[field] || "Unknown field";
  };

  export function formatDateOnly(dateString) {
    const date = new Date(dateString);
  
    const options = { year: 'numeric', month: 'long', day: '2-digit' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }