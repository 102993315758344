import React, {useEffect, useRef, useState, useContext} from 'react';
import {dateElements, formatDateToText, taxesTitle, formatDate, formatCurrency, getFieldLabel} from '../Utils/Helper';
import { auth, provider } from './Firebase';
import {signInWithPopup} from "firebase/auth"
import FileUpload from './FileUpload';
import { UserContext } from "../context/User";
import { BookingContext } from "../context/Booking";
import StripePaymentForm from './Stripe';
import PaymentAnimation from './PaymentAnimation';
import Map from './Map'

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import useCustomer from '../hooks/useCustomer';
import useCleaningFee from '../hooks/useCleaningFee';
import useAccomodationFare from '../hooks/useAccomodationFare';
import useExtraGuestFee from '../hooks/useExtraGuestFee';
import useTaxes from '../hooks/useTaxes';
import useTotal from '../hooks/useTotal';


const BookingPopup = ({ closePopup, selectedDates, property, calendar }) => {
  const apiURL = process.env.REACT_APP_APIURL;
  const { user, updateUser } = useContext(UserContext);
  const { booking, updateBooking, isBookingComplete } = useContext(BookingContext);

  const scrollContainerRef = useRef(null);
  const [fade, setFade] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showAlerts, setShowAlerts] = useState(false);
  const [step, setStep] = useState(0);
  const [reservationId, setReservationId] = useState('');
  const [animationStep, setAnimationStep] = useState(0)

  const { addCustomer } = useCustomer();
  const { addCleaningFee } = useCleaningFee();
  const { addAccommodationFare } = useAccomodationFare({ calendar });
  const { addExtraGuestFee } = useExtraGuestFee();
  const { addTaxes } = useTaxes();
  const getTotal = useTotal();


  // PICTURE AUTO-SLIDER
  useEffect(() => {
    if (property?.pictures?.length) {
      const imageCount = property.pictures.length;
      const interval = setInterval(() => {
        setFade(true);
        setTimeout(() => {
          setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageCount);
          setFade(false);
        }, 500);
      }, 2500);

      return () => clearInterval(interval);
    }
  }, [property?.pictures?.length]);

  // Dates Scrollable Elements
  const scrollByAmount = (direction) => {
    if (!scrollContainerRef.current) return;
    const scrollAmount = direction === "left" ? -120 : 120;
    scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
  };

  // GOOGLE SIGN-IN
  const googleAuth = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        updateUser({
          email: data.user.email,
          name: data.user.displayName.split(" ")[0],
          lastName: data.user.displayName.split(" ")[1],
        });
      })
      .catch((error) => {
        console.error("Authentication failed:", error);
      });
  };

  // VERIFY BOOKING STEP 0 IS COMPLETED
  const verifyBooking = async () => {
    const result = isBookingComplete();
    if (result === true) {
      setStep(1);
      await addExtraGuestFee();
      await addCleaningFee();
      await addAccommodationFare(calendar);
      await addCustomer();
      await addTaxes();
    } else {
      setShowAlerts(true);
    }
  };

  // STRIPE CONFIGURATION
  const stripePromise = loadStripe('pk_test_51QfNexQNhG4pIKsudSYmwaSEO3WXIqBOPW7HXFM4i8PBgU6vcY2fcOzR21Lf7jA08D9XPDBVt8u6zfC36DnYIblh00lVzuls44'); 
  
  return (
    <div className="fixed inset-0 left-0 top-0 bg-black w-full h-full bg-opacity-[50%] flex items-center justify-center" onClick={()=>closePopup()}>
      <div className="w-[1000px] bg-white rounded-xl px-[20px] py-[30px]" onClick={(e) => e.stopPropagation()}>
      {step === 0 && (
        <div className="flex justify-between items-center">
          {/* LEFT SIDE */}
          <div className='border-r border-gray-300 w-[50%] px-[30px]'>
            <div className='mb-[20px]'>  
              <h2 className='text-[25px] text-left font-bold'> {property.nickname} </h2>
              <h2 className='text-[15px] text-left font-medium text-gray-500'> {property.title} </h2>
            </div>  
            <img src={property.pictures[currentImageIndex].original} alt={property.nickname} 
               className={`w-full h-[300px] max-h-[300px] object-cover rounded-lg mx-auto transition-opacity duration-500 ${
                fade ? "opacity-0" : "opacity-100"
              }`}
            />
            <div className="px-[5px]">
              <div className='flex justify-between items-center'>
                <h2 className='text-[20px] text-left my-[15px] text-blue font-medium'> <i class="bi bi-calendar2-week mr-[10px]"></i> Selected Dates </h2>
                <div className='flex justify-between items-center'>
                  <i
                     onClick={() => scrollByAmount("left")} 
                    className="bi bi-chevron-left text-gray-400 hover:text-blue"
                  ></i>
                  <i 
                    onClick={() => scrollByAmount("right")} 
                    className="bi bi-chevron-right text-gray-400 hover:text-blue"
                  ></i>
                </div>
              </div>
              

              <div 
               ref={scrollContainerRef}
               className="flex flex-row gap-2 overflow-x-auto whitespace-nowrap"
              >
                {selectedDates.length > 0 ? (
                  selectedDates.map((date, index) => (
                    <div key={index} className="flex flex-col items-center border border-gray-300 rounded p-2 min-w-[100px]">
                      <p className='text-blue text-[10px]'>{dateElements(date)[0]}</p>
                      <p className='text-gray-500 text-[30px]'>{dateElements(date)[1]}</p>
                      <p className='text-blue text-[10px]'>{dateElements(date)[2]}</p>
                    </div>
                  ))
                ) : (
                  <p>No dates selected.</p>
                )}
              </div>
              
              <div className='grid grid-cols-2 gap-2 mt-[20px]'>
                <div>
                  <h2 className='text-[20px] text-center my-[10px] text-blue font-medium'> <i class="bi bi-box-arrow-in-left mr-[10px]"></i> Check-In </h2>
                  <p className='text-center'>{formatDateToText(selectedDates[0])} </p>
                  <p className='text-e'>{property.defaultCheckInTime} - {property.defaultCheckInEndTime} </p>
                </div>
                <div>
                  <h2 className='text-[20px] text-center my-[10px] text-blue font-medium'> <i class="bi bi-box-arrow-right mr-[10px]"></i> Check-Out </h2>
                  <p className='text-center'> {formatDateToText(selectedDates[selectedDates.length-1])}</p>
                  <p className='text-center'> {property.defaultCheckOutTime} </p>
                </div>             
              </div>
            </div>               
          </div>
          {/* RIGHT SIDE */}
          <div className='flex items-center flex-col w-[50%] overflow-y-auto h-[650px]'>
            {user.name == '' ? (
              <div className='flex flex-col justify-between items-center w-[80%] border border-gray-300 mx-auto rounded-lg p-2 gap-[20px] py-[30px] my-auto'>
                <img
                  className='block h-[50px]'
                  src="https://res.cloudinary.com/dxfi1vj6q/image/upload/v1706677126/IMG_0018_xlfgnj.png"
                  alt="Google Icon"
                />
                <p className='text-gray-500 text-[20px]'>Book With Google</p>
                <button
                  onClick={googleAuth}
                  className='bg-blue text-white w-[80%] py-[10px] rounded-full cursor-pointer mt-[20px] hover:opacity-80'
                >
                  Sign In
                </button>
              </div>
            ) : (
              <div className='w-full px-[30px]'>
                <div>
                  <h2 className='text-[20px] text-left text-blue font-medium'> <i className="bi bi-person-circle mx-[10px]"></i> Contact Details </h2>
                  {/* FORM */}
                  <div className='grid grid-cols-2 gap-2'>
                    <div className='flex items-center gap-2 mt-[20px]'>
                      <i class="bi bi-person ml-[20px] -mr-[40px] relative"></i>
                      <input type='text' value={user.name} className='border border-gray-300 p-2 rounded w-full pl-[50px]' placeholder='First Name'/>
                    </div>
                    <div className='flex items-center gap-2 mt-[20px]'>
                      <i class="bi bi-person ml-[20px] -mr-[40px] relative"></i>
                      <input type='text' value={user.lastName} className='border border-gray-300 p-2 rounded w-full pl-[50px]' placeholder='Last Name'
                       onChange={(e) => updateUser({ lastName: e.target.value })}/>
                    </div>
                  </div>
                  <div className='flex items-center gap-2 mt-[20px]'>
                    <i class="bi bi-envelope ml-[20px] -mr-[40px] relative"></i>
                    <input type='text' value={user.email} className='border border-gray-300 p-2 rounded w-full pl-[50px]' placeholder='Email Address'/>
                  </div>
                  <div className='flex items-center gap-2 mt-[20px]'>
                    <i class="bi bi-telephone ml-[20px] -mr-[40px] relative"></i>
                    <input type='text' value={user.phone} className='border border-gray-300 p-2 rounded w-full pl-[50px]' placeholder='Phone Number'
                    onChange={(e) => updateUser({ phone: e.target.value })} />
                  </div>
                  <FileUpload />
                  {/* GUEST COUNTER */}
                  <div className='flex items-center justify-between gap-2 mt-[20px] px-[20px] border border-gray-300 rounded py-[8px]'>
                    <h2 className='text-[20px] text-left text-blue font-medium'> <i class="bi bi-people mr-[10px]"></i> Guests </h2>
                    <div className="grid grid-cols-[1fr_1fr_1fr] items-center gap-2 mt-[3px]">
                      {booking.guests > 1 ? (
                        <i
                          className="bi bi-dash-circle text-[25px] text-gray-400 hover:text-blue"
                          onClick={() => updateBooking({ guests: booking.guests - 1 })}
                        ></i>
                      ) : (
                        <div className="w-[25px] h-[25px] invisible"></div> // Placeholder to keep space
                      )}
                      <p className="mx-[10px] text-center w-[20px]">{booking.guests}</p> {/* Fixed width for numbers */}
                      <i
                        className="bi bi-plus-circle text-[25px] text-gray-400 hover:text-blue"
                        onClick={() => updateBooking({ guests: booking.guests + 1 })}
                      ></i>
                    </div>
                  </div>
                   {/* ALERTS */}
                  <div>
                    {isBookingComplete() !== true && showAlerts &&(
                      <div className="mt-4 bg-red-100 border border-red-300 text-red-700 p-3 rounded text-left">
                        <h2 className="font-bold ml-[20px]">Missing Information:</h2>
                        <ul className="list-disc ml-6">
                          {isBookingComplete().map((field, index) => (
                            <li key={index} className="text-sm">
                              {getFieldLabel(field)} {/* Convert field names into readable labels */}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <button 
                    className='text-gray-400 hover:text-blue text-[15px] hover:opacity-80 block ml-auto mr-0 mt-[30px]'
                    onClick={()=>verifyBooking()}
                  > 
                    <i class="bi bi-credit-card-2-front"></i> Proceed To Payment <i class="bi bi-arrow-right-short"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {step === 1 && (
         <div className="flex justify-between items-center">
         {/* LEFT SIDE */}
         <div className="border-r border-gray-300 w-[50%] h-[600px] px-[30px] flex flex-col justify-between">
          <div>
            <button onClick={()=>setStep(0)} className='text-gray-400 hover:text-blue text-[15px] hover:opacity-80 block mr-auto ml-0 -mt-[30px]'> <i class="bi bi-arrow-left-short"></i> Back </button>
            <h2 className='text-[20px] text-left my-[15px] text-blue font-medium'> <i class="bi bi-calculator mr-[10px]"></i> Sumary </h2>
            <div>
              <div className='flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150'>
                <p> Accomodation Fare </p>
                <p> {formatCurrency(booking.balances.accommodationFare)} USD</p>
              </div>
              <div className='flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150'>
                <p> Processing Fee</p>
                <p> {formatCurrency(booking.balances.serviceFee)} USD</p>
              </div>
              <div className='flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150'>
                <p> Extra Guest Fee</p>
                <p> {formatCurrency(booking.balances.extraGuestFee)} USD</p>
              </div>
              <div className='flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150'>
                <p> Cleaning Fee</p>
                <p> {formatCurrency(booking.balances.cleaningFee)} USD</p>
              </div>
              {booking.balances.taxes && booking.balances.taxes.length > 0 ? (
                booking.balances.taxes.map((tax, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150"
                  >
                    <p>{tax.type} ({tax.porcentage}%)</p>
                    <p>{formatCurrency((booking.balances.accommodationFare + booking.balances.serviceFee + booking.balances.cleaningFee) * (tax.porcentage / 100))} USD</p>
                  </div>
                ))
              ) : (
                <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
                  <p>Tax</p>
                  <p>Loading ...</p>
                </div>
              )}
              <div className='flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150'>
                <p className='font-semibold'> Total </p>
                <p className='text-blue font-semibold'> {formatCurrency(getTotal())} USD</p>
              </div>
            </div>
          </div>
          <div>
            <div className='flex justify-between items-center'>
                <h2 className='text-[20px] text-left my-[15px] text-blue font-medium'> <i class="bi bi-calendar2-week mr-[10px]"></i> Selected Dates </h2>
                <div className='flex justify-between items-center'>
                  <i
                     onClick={() => scrollByAmount("left")} 
                    className="bi bi-chevron-left text-gray-400 hover:text-blue"
                  ></i>
                  <i 
                    onClick={() => scrollByAmount("right")} 
                    className="bi bi-chevron-right text-gray-400 hover:text-blue"
                  ></i>
                </div>
            </div>
              
            <div 
             ref={scrollContainerRef}
             className="flex flex-row gap-2 overflow-x-auto whitespace-nowrap"
            >
              {selectedDates.length > 0 ? (
                selectedDates.map((date, index) => (
                  <div key={index} className="flex flex-col items-center border border-gray-300 rounded p-2 min-w-[100px]">
                    <p className='text-blue text-[10px]'>{dateElements(date)[0]}</p>
                    <p className='text-gray-500 text-[30px]'>{dateElements(date)[1]}</p>
                    <p className='text-blue text-[10px]'>{dateElements(date)[2]}</p>
                  </div>
                ))
              ) : (
                <p>No dates selected.</p>
              )}
            </div>
          </div>
         </div>
         {/* RIGHT SIDE */}
         <div className='flex items-center flex-col w-[50%] overflow-y-auto h-[650px]'>
          <Elements stripe={stripePromise}>
            <StripePaymentForm  
              nextStep={()=>setStep(step + 1)}
              sendReservationId={(id)=>setReservationId(id)}
            />
          </Elements>
         </div>
       </div>
      )}

      {step === 2 && (
         <div className="flex justify-between items-center">
         {/* LEFT SIDE */}
         {animationStep == 2 && (
           <div className="border-r border-gray-300 w-[50%] px-[30px] animate-slideIn ">
             <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
               <p> Booking ID </p>
               <p className="text-gray-400">
                 {reservationId === '' ? 'Loading ...' : reservationId}
               </p>
             </div>
             <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
               <p> Property </p>
               <p className="text-gray-400"> {booking.property.nickname}</p>
             </div>
             <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
               <p> Type </p>
               <p className="text-gray-400"> {booking.property.roomType}</p>
             </div>
             <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
               <p> Street </p>
               <p className="text-gray-400"> {booking.property.address.street}</p>
             </div>
             <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
               <p> City </p>
               <p className="text-gray-400">
                 {booking.property.address.city}, {booking.property.address.state}
               </p>
             </div>
             <div className="flex items-center justify-between border-b border-gray-300 py-[10px] px-[10px] hover:bg-gray-100 hover:pl-[20px] transition-all delay-150">
               <p> Guests </p>
               <p className="text-gray-400"> {booking.guests} people </p>
             </div>
   
             <br />
   
             <Map
               title={booking.property.nickname}
               lat={booking.property.address.lat}
               long={booking.property.address.lng}
               showLocation={true}
             />
   
             <div className="flex flex-row justify-between mt-[20px]">
               <div>
                 <h2 className="text-[20px] text-center my-[10px] text-blue font-medium">
                   {' '}
                   <i className="bi bi-box-arrow-in-left mr-[10px]"></i> Check-In{' '}
                 </h2>
                 <p className="text-center">{formatDateToText(selectedDates[0])} </p>
                 <p className="text-center">
                   {property.defaultCheckInTime} - {property.defaultCheckInEndTime}{' '}
                 </p>
               </div>
               <div>
                 <h2 className="text-[20px] text-center my-[10px] text-blue font-medium">
                   {' '}
                   <i className="bi bi-box-arrow-right mr-[10px]"></i> Check-Out{' '}
                 </h2>
                 <p className="text-center">
                   {' '}
                   {formatDateToText(selectedDates[selectedDates.length - 1])}
                 </p>
                 <p className="text-center"> {property.defaultCheckOutTime} </p>
               </div>
             </div>
           </div>
         )}
         {/* RIGHT SIDE */}
         <div className={`flex items-center flex-col ${animationStep == 2 ? 'w-[50%]' : 'w-[100%]'} overflow-y-auto h-[650px]`}>
           <PaymentAnimation onStepChange={(value)=>setAnimationStep(value)}/>
         </div>
       </div>
      )}
      </div>
    </div>
  )}

export default BookingPopup;
