import React, { createContext, useState, useContext, useEffect } from "react";
import { UserContext } from "./User"; // Import UserContext

export const BookingContext = createContext();

export const BookingProvider = ({ children }) => {
  const { user } = useContext(UserContext); // Access UserContext
  const [booking, setBooking] = useState({
    property: null,       // Property details
    selectedDates: [],    // Array of selected dates
    guests: 1,            // Number of guests
    user: user,           // Sync with UserContext
    balances: {           // Balances object
      taxes: [],
      accommodationFare: 0,
      cleaningFee: 0,
      serviceFee: 0,
      extraGuestFee: 0,
    },
  });

  // Update the booking context when user data changes
  useEffect(() => {
    setBooking((prev) => ({
      ...prev,
      user, // Sync the user object with UserContext
    }));
  }, [user]);

  const updateBooking = (updatedFields) => {
    setBooking((prev) => ({
      ...prev,
      ...updatedFields,
    }));
  };

  const updateBalances = (updatedFields) => {
    setBooking((prev) => ({
      ...prev,
      balances: {
        ...prev.balances,
        ...updatedFields,
      },
    }));
  };

  // Check if the booking object is complete
  const isBookingComplete = () => {
    const missingFields = [];
  
    if (!booking.property) missingFields.push("property");
    if (!booking.selectedDates || booking.selectedDates.length === 0)
      missingFields.push("selectedDates");
    if (booking.guests <= 0) missingFields.push("guests");
  
    // Validate user fields
    const userFields = ["name", "lastName", "email"];
    userFields.forEach((field) => {
      if (!booking.user[field] || booking.user[field].trim() === "") {
        missingFields.push(`user.${field}`);
      }
    });
  
    // Validate phone (must be at least 10 digits)
    if (!booking.user.phone || booking.user.phone.trim().length < 10) {
      missingFields.push("user.phone");
    }
  
    // Validate photoId (not a string, just check for emptiness)
    if (!booking.user.photoId) {
      missingFields.push("user.photoId");
    }
  
    return missingFields.length === 0 ? true : missingFields;
  };

  useEffect(()=>{
    console.log(booking);
    
  },[booking])
  
  return (
    <BookingContext.Provider value={{ booking, updateBooking, updateBalances, isBookingComplete }}>
      {children}
    </BookingContext.Provider>
  );
};
