import { useContext } from 'react';
import { BookingContext } from "../context/Booking";
import { fetchCalendar } from "../Utils/Helper";
import { formatDate } from "../Utils/Helper";


const useLastAvailability = () => {
  const { booking } = useContext(BookingContext); // Access context
  const selectedDates = booking?.selectedDates || []; // Ensure selectedDates is defined

  // Function to check if the selected dates are still available
  const isStillAvailable = async () => {
    console.log('CHECKING LAST AVAILABILITY.. ');
    
    if (!booking?.property?._id) {
      console.warn("Property ID is missing in booking.");
      return false; // No property to check
    }

    const { disabledDates} = await fetchCalendar(booking.property._id);
    
    const formattedDates = disabledDates.map(formatDate);
    const formattedSet = new Set(formattedDates); // Create a Set from formattedDates

    return !selectedDates.some((date) => formattedSet.has(date)); // Check for overlap
  };

  return { isStillAvailable };
};

export default useLastAvailability;
