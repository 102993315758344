import { useContext } from 'react';
import { db, storage } from '../components/Firebase'; // Update with your actual Firebase path
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { UserContext } from '../context/User'; // Import UserContext

const useCustomer = () => {
  const { user } = useContext(UserContext);
  
  async function uploadID() {
    if (!user.photoId) {
        console.error("No file to upload");
        return;
      }
      const imageRef = ref(storage, `customersID/${user.email}`);
      
      try {
        // Upload the file
        const snapshot = await uploadBytes(imageRef, user.photoId);
        console.log("File uploaded successfully:", snapshot);
    
        // Get the download URL
        const downloadURL = await getDownloadURL(snapshot.ref);
        console.log("Download URL:", downloadURL);
    
        return downloadURL; // Return the URL for further use
      } catch (error) {
        console.error("Error uploading file:", error);
        throw error; // Rethrow the error if needed
      }
  }
  
  const addCustomer = async () => {
    try {
      // Check if the email already exists
      const docRef = doc(db, 'Customers', user.email);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        throw new Error('Customer with this email already exists!');
      }


      // Add the customer if the email is unique
      await setDoc(docRef, {
        name: user.name.toLowerCase(),
        lastName: user.lastName.toLowerCase(),
        email: user.email.toLowerCase(),
        phone: user.phone,
        photoId: await uploadID()
      });

      return { success: true, message: 'Customer added successfully!' };
    } catch (error) {
      console.error('Error adding customer:', error);
      return { success: false, message: error.message || 'Error adding customer!' };
    }
  };

  return { addCustomer };
};

export default useCustomer;
