import React, { useEffect, useState, createContext } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { app } from "../components/Firebase";

const PropertiesContext = createContext({
  properties: [],
  setProperties: () => {},
  getPropertyByNickname: () => {},
  getRulesFirebase: () => {},
});

const PropertiesProvider = ({ children }) => {
  const apiURL = process.env.REACT_APP_APIURL;
  const [properties, setProperties] = useState([]);
  const db = getFirestore(app);

  useEffect(() => {
    fetchProperties();
  }, []);

  // Fetch properties from the API
  const fetchProperties = async () => {
    try {
      const response = await fetch(`${apiURL}/api/getProperties`);
      if (!response.ok) {
        throw new Error(`Error fetching properties: ${response.statusText}`);
      }
      const data = await response.json();
      setProperties(data.results);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  // Get a single property by its nickname
  const getPropertyByNickname = (nickname) => {
    const property = properties.find((prop) => prop.nickname === nickname) || null;
    console.log("Property fetched by nickname:", property);
    return property;
  };

  // Fetch rules from Firebase for a specific property
  const getRulesFirebase = async (propertyName) => {
    try {
      const docRef = doc(db, "Properties", propertyName);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const { min, max } = docSnap.data();
        return [min, max];
      } else {
        console.warn(`No rules found for property: ${propertyName}`);
        return null;
      }
    } catch (error) {
      console.error("Error fetching rules from Firebase:", error);
      return null;
    }
  };

  return (
    <PropertiesContext.Provider
      value={{
        properties,
        setProperties,
        getPropertyByNickname,
        getRulesFirebase,
      }}
    >
      {children}
    </PropertiesContext.Provider>
  );
};

export { PropertiesContext, PropertiesProvider };
