import './App.css';
import React from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import Home from "./Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Rentals from './Rentals';
import Property from './Property';
import Services from './Services';
import Login from './Login';
import Contact from './Contact';
import Support from './Support';
import Terms from './Terms';
import Chat from './Chat';
import Reservation from './Reservation';
import PrintableReservation from './components/PrintableReservation';

import NewRentals from './pages/rentals';
import NewProperty from './pages/property';
import NewReservation from './pages/reservation';
import PrintReservation from './pages/printReservation';
import Contract from './pages/contract'

import { PropertiesProvider } from './context/Properties';
import { UserProvider } from "./context/User";
import { BookingProvider } from './context/Booking';
import { MyBookingProvider } from './context/MyBooking';

// App wrapper to handle conditional Header and Footer rendering
function AppWrapper() {
  const location = useLocation();

  // Define routes where Header and Footer should be hidden
  const hideHeaderFooter = location.pathname.startsWith("/print-reservation");

  return (
    <div className="App">
      {!hideHeaderFooter && <Header />}
      <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/property/:nickname" element={<NewProperty />} />
          <Route path="/services" element={<Services />} />
          <Route path="/rentals" element={<NewRentals />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/support" element={<Support />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/printable-reservation" element={<PrintableReservation />} />

          {/* NEW PAGES */}
          <Route path="/new-property/:nickname" element={<NewProperty />} />
          <Route path="/new-reservation/:id" element={<NewReservation />} />
          <Route path="/print-reservation/:id" element={<PrintReservation />} />
          <Route path="/contract/:id" element={<Contract />} />
        </Routes>
      </div>
      {!hideHeaderFooter && <Footer />}
    </div>
  );
}

// Main App component with Providers
function App() {
  return (
    <PropertiesProvider>
      <UserProvider>
        <BookingProvider>
          <MyBookingProvider>
            <Router>
              <AppWrapper />
            </Router>
          </MyBookingProvider>
        </BookingProvider>
      </UserProvider>
    </PropertiesProvider>
  );
}

export default App;
