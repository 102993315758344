import { useContext } from 'react';
import { BookingContext } from "../context/Booking";
import {formatDate} from '../Utils/Helper';

const useAccomodationFare = ({ calendar = [] } = {}) => { 
  const { booking, updateBalances } = useContext(BookingContext);
  const selectedDates = booking.selectedDates;

  const addAccommodationFare = () => {
    let total = 0;

    // Format the calendar dates
    const formattedCalendar = calendar.map((item) => ({
      ...item,
      date: formatDate(item.date),
    }));

    // Calculate the total price for selectedDates
    total = selectedDates.slice(0, -1).reduce((acc, selectedDate) => {
      const calendarEntry = formattedCalendar.find(
        (item) => item.date === selectedDate
      );
      return calendarEntry ? acc + calendarEntry.price : acc;
    }, 0);


    updateBalances({
      accommodationFare: total,
      serviceFee: total * 0.037,
    });
    return true;
  };

  return { addAccommodationFare };
};


export default useAccomodationFare;
